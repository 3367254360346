<template>
  <Layout>
    <div class="app-charge">
      <div class="tips">
        <a-alert
          message="注意: 请在服务过期后充值/兑换，充值后会清空原来的点数和服务时间，错误操作不返还点数！。"
          type="warning"
          show-icon
        />
      </div>
      <a-row class="fee-list" :gutter="16">
        <a-col :span="12" v-for="(item, index) in list" :key="item.code">
          <div
            class="fee-item"
            :class="current === index ? 'active' : null"
            @click="select(index)"
          >
            <div class="price"><small>￥</small>{{ item.title }}</div>
            <div class="desc">{{ item.total }}点/月</div>
          </div>
        </a-col>
      </a-row>
      <a-button
        :disabled="!enablePay"
        @click="pay"
        size="large"
        type="primary"
        block
        :loading="loading"
        style="margin-top:10px;height:44px;"
      >
        微信支付
      </a-button>

      <div style="text-align:center;margin:20px;">
        <a-button @click="exchangeVisible = true" type="link">兑换码</a-button>
      </div>

      <!-- 付款二维码 -->
      <a-modal
        :destroyOnClose="true"
        :footer="null"
        :visible="dialogVisible"
        @cancel="closeDialog"
        :bodyStyle="{
          'text-align': 'center'
        }"
      >
        <div class="title">
          微信扫码付款
        </div>
        <vue-qrcode :width="200" :value="qrcode" />
        <div>
          如果付款后长时间没有到账，请联系客服
        </div>
      </a-modal>

      <!-- 兑换 -->
      <a-modal
        title="兑换"
        :destroyOnClose="true"
        :visible="exchangeVisible"
        @cancel="exchangeVisible = false"
        @ok="exchangeCode"
        okText="提交"
      >
        <a-form-model :model="form" ref="form" autocomplete="off">
          <a-form-model-item
            required
            label=""
            prop="code"
            :rules="[
              { required: true, message: '兑换码必填' },
              { len: 10, message: '兑换码必填' }
            ]"
          >
            <a-input v-model="form.code" placeholder="兑换码" />
          </a-form-model-item>
        </a-form-model>
      </a-modal>
    </div>
  </Layout>
</template>

<script>
import { mapState } from "vuex";

import Layout from "@/layout/index.vue";

import VueQrcode from "vue-qrcode";

const OrderSvc = require("@/service/order");

export default {
  name: "Charge",

  data() {
    return {
      list: [
        { code: "add1w", title: "199", total: "1万" },
        { code: "add2w", title: "299", total: "2万" }
      ],
      current: -1,
      loading: false,

      qrcode: "",
      orderId: "",
      dialogVisible: false,

      exchangeVisible: false,
      form: { code: "" }
    };
  },

  computed: {
    ...mapState(["user", "isWeixin"]),

    enablePay() {
      return this.current >= 0;
    }
  },

  methods: {
    select(index) {
      this.current = index;
    },

    async pay() {
      if (this.current < 0) {
        return;
      }

      this.loading = true;

      const code = this.list[this.current].code;
      const res = await OrderSvc.create(code);
      const { code_url, id } = res;

      this.loading = false;

      this.qrcode = code_url;
      this.orderId = id;

      this.dialogVisible = true;

      this.timer = setTimeout(this.checkOrderStatus, 5000);
    },

    // 兑换
    async exchangeCode() {
      this.$refs.form.validate(async valid => {
        if (!valid) return false;

        try {
          const { code } = this.form;
          await OrderSvc.exchangeCode(code);

          this.$message.info("兑换成功");

          this.$refs.form.resetFields();
          this.exchangeVisible = false;
        } catch (err) {
          console.error(err);
          this.$message.error("兑换失败：" + err.message);
        }
      });
    },

    async checkOrderStatus() {
      try {
        const { status } = await OrderSvc.orderStatus(this.orderId);
        if (status === 1) {
          this.dialogVisible = false;
          this.$message.info("充值成功");
        } else {
          this.timer = setTimeout(this.checkOrderStatus, 5000);
        }
      } catch (err) {
        console.error(err);

        this.timer = setTimeout(this.checkOrderStatus, 5000);
      }
    },

    closeDialog() {
      this.dialogVisible = false;

      if (this.timer) {
        clearTimeout(this.timer);
      }
    }
  },

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },

  components: {
    VueQrcode,
    Layout
  }
};
</script>

<style lang="scss">
.app-charge {
  width: 100%;
  height: 100vh;

  .balance-box {
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;

    .balance {
      font-size: 22px;
      color: #f00;
    }
  }

  .tips {
    margin-bottom: 10px;
  }

  .fee-list {
    .fee-item {
      padding: 10px;
      margin-bottom: 10px;
      border: 2px solid #eee;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;

      .price {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .desc {
        font-size: 14px;
      }

      &.active {
        border-color: #1890ff;
        color: #1890ff;
      }

      color: #000;
      font-size: 24px;

      small {
        font-size: 18px;
        text-decoration: none;
      }
    }
  }

  .bank {
    padding: 20px;
    font-size: 18px;
    margin-top: 10px;
  }
}
</style>
